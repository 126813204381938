<template>
    <b-modal
        id="show-file-modal"
        hide-header
        hide-footer
        no-stacking
        centered
        size="lg"
        @show="onShow"
    >
        <div class="header">
            <div class="header-content">
                <p>Visualizar resultado</p>
                <CloseIcon class="icon" @click="closeModal" />
            </div>
        </div>

        <div class="body">
            <div class="body-content">
                <div class="result" v-if="!hasUrl">
                    <p class="text-danger text-bold">Resultado indisponível.</p>

                    <p>Por favor, verifique se o exame foi concluído no equipamento. Tente novamente mais tarde.</p>

                </div>

                <div class="result" v-else>
                    <p class="text-success text-bold">Resultado disponível.</p>

                    <p>O exame foi realizado no equipamento e agora é possível solicitar o devido laudo.</p>
                </div>
            </div>
        </div>

        <div class="footer">
            <div class="foot-content">
                <div v-if="!hasUrl">
                    <b-button
                    variant="primary"
                    @click="closeModal"
                    
                    >
                    Concluir
                </b-button>
                </div>
                <div class="d-flex flex-row justify-content-end" v-else>
                    <b-button
                        class="mr-2"
                        variant="outline-primary"
                        @click="requestReport"

                    >
                        Solicitar laudo
                    </b-button>
                    <b-button
                        class=""
                        variant="primary"
                        @click="showFile"
                    >
                        Visualizar resultado
                    </b-button>
                </div>
            </div>
        </div>


    </b-modal>
</template>
<script>
import api from '@/modules/exams/api'
export default {
    components: {
        CloseIcon: () => import('@/assets/icons/close.svg')
    },
    props: {
        labpacsItem: {
            type: Object,
            default: null
        },
        patientProp: null,
        itemId: null
    }, 
    data() {
        return {
            labpacs_item: null,
            patient: null,
            item_id: null,
            url: null
        }
    },
    mounted() {
        this.labpacs_item = this.labpacsItem
        this.patient = this.patientProp
        this.item_id = this.itemId
    },
    computed: {
        hasStudyInstanceUid() {
            return !!this.labpacs_item?.study_instance_uid
        },
        hasUrl() {
            return !!this.url
        }
    },
    methods: { 
        onShow() {
            this.getUrl()
        },
        closeModal() {
            this.$emit('close')
        },

        showFile() {
            window.open(this.url, '_blank')
        },

        requestReport() {
            const data = {
                item_id: this.item_id,
                patient: this.patient,
                labpacsItem: {
                    accession_number: this.labpacs_item.accession_number,
                } 
            }
            this.$emit('request-report', data)
            this.closeModal()
        },
        getUrl() {
            const isLoading = this.$loading.show()
            api.getUrlDicon(this.labpacs_item.id)
                .then(response => {
                    this.url = response.data.url
                })
                .catch(error => {
                    console.log(error)
                }).finally(() => {
                    isLoading.hide()
                })
        }

    },
    watch: {
        labpacsItem: {
            handler() {
                this.labpacs_item = this.labpacsItem
            },
            immediate: true
        },
        patientProp: {
            handler() {
                this.patient = this.patientProp
            },
            immediate: true
        },
        itemId: {
            handler() {
                this.item_id = this.itemId
            },
            immediate: true
        }
    }

}
</script>
<style lang="scss" scoped>
.header {
    border-bottom: #D9DFF2 1px solid;
    .header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 13px 0;


        p {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;

            color: #525C7A;
        }


        svg {
          height: 24px;
          width: 24px;
          fill: #7F91D2;
          cursor: pointer;
        }
    }
}

.body .body-content {
    padding: 10px 0;

    .result {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        p {
            padding: 5px 0;
        }
    }
}

.footer {
    border-top: #D9DFF2 1px solid;
    padding-top: 10px;
    .foot-content {

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
.icon {
    cursor: pointer;
    width: 24px;
    height: 24px;    
}
</style>